<template>
  <div class="d-flex justify-content-center full-height full-width align-items-center">
    <div class="error-container text-center">
      <!--      <h1 class="error-number">404</h1>-->
      <h2 class="semi-bold">{{ msg }}</h2> <br/><br/><br/><br/>
<!--      <router-link :to="{ name: ROUTES.ADMIN.ALL_USERS_LIST,-->
<!--          query: {-->
<!--            'filters.apartment_id.equal' : 'all',-->
<!--            'filters.status_enabled.equal' : 'all'-->
<!--          }}" style="text-decoration: underline">管理画面へ</router-link>-->
      <p v-if="valid==true" @click="refreshToken" style="text-decoration: underline; color: #007bff;cursor: pointer">管理画面へ</p>
    </div>
  </div>
</template>

<script>
import {ADMIN_URL_API, ENDPOINT} from "@constants/api";
import {logoutAction} from "@utils";
import {APP_NAME} from "@constants";
import {store} from "@store";
export default {
  name: "UserVerifyEmail",
  data() {
    return {
      msg: "",
      valid : true,
      status: false,
    }
  },
  mounted: function () {
    this.$request.get(this.ENDPOINT.VERIFY_PASSWORD(this.$route.query.id,this.$route.query.token, this.$route.query.adminUpdateId)).then(res => {
      if (res.hasErrors()) {
        this.valid = false;
        this.msg = this.$t("auth.url invalid");
      } else {
        this.msg = res.data.msg;
        if(res.data.status == 1) {
          this.status = true;
        }
      }
    })
  },
  methods : {
    async refreshToken() {
      await this.$request.get(this.ENDPOINT.VERIFY_ACTIVE_USER(this.$route.query.id)).then(res => {
        if (res.hasErrors()) {
          this.status = false;
          // this.msg = this.$t("auth.url invalid");
        } else {
          // this.msg = res.data.msg;
          if(res.data.status == 1) {
            this.status = true;
          } else {
            this.status = false;
          }
        }
      })
      if (this.status == false) {
        this.__noticeError("ログインに失敗しました。ログイン情報をご確認いただき、再度ログインをお試しください。");
        return false;
      }
      store.commit('auth/setAuthRole', "admin")
      let token = this.$store.getters["auth/getToken"];

      let ptb = atob(this.$route.query.tpb);
      let em = atob(this.$route.query.em);
      let request = {
        email: em,
        password: ptb,
        role: 1,
      };
      let response = await this.$request.post(
        ADMIN_URL_API +  this.ENDPOINT.AUTH_LOGIN,
        request
      );
      if (!response.hasErrors()) {
        await this.$request.delete(ADMIN_URL_API +  ENDPOINT.AUTH_LOGOUT, {} , {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
        this.$store.commit("auth/removeAuth")
        store.commit('auth/setAuthRole', "admin")
        this.$store.commit(`auth/updateAuth`, {
          user: response.data.data,
          token: response.headers.authorization,
          expire_time: response.headers["token-expire-time"],
        });
        window.location.href = "/admin/accounts?filters.apartment_id.equal=all&filters.status_enabled.equal=all"
      } else {
        this.$refs.notice.setError(
          response.data.msg ? response.data.msg : this.$t("common.error")
        );
      }

    },
  }
}
</script>
